import React from "react"
import { Container } from "react-bootstrap"
import logo01 from "../assets/images/logos/01.png"
import logo02 from "../assets/images/logos/02.png"
import logo03 from "../assets/images/logos/03.png"
import logo04 from "../assets/images/logos/04.png"
import logo05 from "../assets/images/logos/05.png"
import logo06 from "../assets/images/logos/06.png"
import logo07 from "../assets/images/logos/07.png"
import logo08 from "../assets/images/logos/08.png"
import teamOne from "../assets/images/team/01.png"
import teamTwo from "../assets/images/team/02.png"
import teamThree from "../assets/images/team/03.png"
import teamFour from "../assets/images/team/04.png"
import teamFive from "../assets/images/team/05.png"
import teamSix from "../assets/images/team/06.png"
import Layout from "../components/Layout"

const About = () => (
  <Layout>
    <div>
      <section class="py-8 py-md-11 border-bottom">
        <Container>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">
              <h2 class="display-3">A strong team, focussed on increasing ticket sales.</h2>

              <p class="lead text-muted mb-7 mb-md-9">
                We have a decade of experience in the event industry. Lumis.ai is the answer to
                everything we learned so far.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-row">
                <div class="col-4">
                  <img src={teamOne} class="img-fluid rounded shadow-lg" alt="..." />
                </div>
                <div class="col-3">
                  <img src={teamTwo} class="img-fluid rounded shadow-lg mb-4" alt="..." />

                  <img src={teamThree} class="img-fluid rounded shadow-lg mt-1" alt="..." />
                </div>
                <div class="col-5">
                  <div class="form-row mb-4">
                    <div class="col-5">
                      <img src={teamFour} class="img-fluid rounded shadow-lg" alt="..." />
                    </div>
                    <div class="col-7">
                      <img src={teamFive} class="img-fluid rounded shadow-lg" alt="..." />
                    </div>
                  </div>

                  <img src={teamSix} class="img-fluid rounded shadow-lg mt-1" alt="..." />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section class="py-8 py-md-11">
        <Container>
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-7 text-center">
              <h2>We strive to work with the best.</h2>

              <p class="font-size-lg text-muted mb-7 mb-md-9">
                Lumis.ai is the most advanced ticketing system to date. Just ask any one of our
                customers.
              </p>
            </div>
          </div>
          <div class="row">
            <div className="col-3">
              <img src={logo01} class="img-fluid" alt="..." />
            </div>
            <div className="col-3">
              <img src={logo02} class="img-fluid" alt="..." />
            </div>
            <div className="col-3">
              <img src={logo03} class="img-fluid" alt="..." />
            </div>
            <div className="col-3">
              <img src={logo04} class="img-fluid" alt="..." />
            </div>
          </div>
          <div class="row mt-6">
            <div className="col-3">
              <img src={logo05} class="img-fluid" alt="..." />
            </div>
            <div className="col-3">
              <img src={logo06} class="img-fluid" alt="..." />
            </div>
            <div className="col-3">
              <img src={logo07} class="img-fluid" alt="..." />
            </div>
            <div className="col-3">
              <img src={logo08} class="img-fluid" alt="..." />
            </div>
          </div>
        </Container>
      </section>
    </div>
  </Layout>
)

export default About
